import React, { useState } from 'react';
import { Row, Col, Button, TextInput } from 'react-materialize';
import useAxios from 'axios-hooks'
import { debounce } from 'lodash';

import ReportList from "./Reports/ReportList";

import { smoothScrollToTop } from "../Utils/ui";
import styles from "./Reports.module.css";

function Reports() {
	const [pageApiUrl, setPageApiUrl] = useState(process.env.REACT_APP_API_URL + '/report');
	const [{ data, loading, error }, refetch] = useAxios(pageApiUrl);

	const setPage = url => {
		setPageApiUrl(url);
		setTimeout(() => {
			smoothScrollToTop();
		}, 500);
	};

	const search = debounce(e => {
		if (!!e.target.value) {
			setPage(process.env.REACT_APP_API_URL + '/report/search/' + e.target.value);
		}
	}, 250);

	const ready = data && !loading && !error;

	return (
		<div className={!ready ? styles.reportsPreload : ''}>
			<Row>
				<Col s={12}>
					<h3 className="center-align">Trip Reports</h3>
					{!data && loading && <p className="center-align">Loading...</p>}
					{error && <p className="center-align">
						Error, could not load trip reports!<br/>
						<Button waves="light" onClick={refetch}>
							Try Again
						</Button>
					</p>}
				</Col>
			</Row>
			<Row>
				<TextInput name="search" placeholder="Search..." icon="search" s={12} onChange={e => {
					e.persist();
					search(e);
				}}/>
			</Row>
			<Row>
				<Col s={12}>
					{data && <ReportList reports={data.data}/>}
					{data && data.links &&
						<div>
							{data.links.prev && <Button waves="light" onClick={() => setPage(data.links.prev)} className={styles.prevBtn}>
								Prev
							</Button>}
							{data.links.next && <Button waves="light" onClick={() => setPage(data.links.next)} className={styles.nextBtn}>
								Next
							</Button>}
							{loading && <span className="center-align">Loading...</span>}
						</div>
					}
				</Col>
			</Row>
		</div>
	);
}

export default Reports;
