import React from 'react';
import { Row, Col, Button } from 'react-materialize';
import useAxios from 'axios-hooks'
import ReactMarkdown from 'react-markdown';

import { cleanData, getDateByline } from '../../Utils/data';
import { commaHandler } from "../../Utils/text";

import styles from "./Report.module.css";

function Report({ match }) {
	const [{ data, loading, error }, refetch] = useAxios(
		process.env.REACT_APP_API_URL + '/report/' + match.params.uri
	);

	const report = cleanData(data);

	return (
		<div className={!report ? styles.reportPreload : ''}>
			{loading && <p className="center-align">&nbsp;</p>}
			{error && <p className="center-align">
				Error, could not load trip report!<br/>
				<Button waves="light" onClick={refetch}>
					Try Again
				</Button>
			</p>}
			{data && <div>
				<Row>
					<Col s={12} className={styles.titleText}>
						<h3 className="center-align">{report.title}</h3>
						<p className="center-align">by {report.authors.map((author, ind) => {
							return author.name + commaHandler(report.authors.length, ind);
						})}</p>
						{!!report.date && <p className="center-align">
							{getDateByline(report.date, report.end_date, report.month_only)}
						</p>}
						{report.newsletter && <p className="center-align">
							Find this article (with pictures and more) in <a href={report.newsletter.url}><i>Desert Sage</i> #{report.newsletter.issue_number}</a>
						</p>}
					</Col>
					<Col s={12} className={styles.reportText}>
						<ReactMarkdown source={report.text} escapeHtml={false}/>
					</Col>
				</Row><br/>
			</div>}
		</div>
	);
}

export default Report;
