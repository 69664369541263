import React from 'react';

import styles from "./NewsletterList.module.css";

function NewsletterList(props) {
	return (
		<div className={styles.newsletterList}>
			{props.newsletters.map((newsletter) => {
				if (newsletter.issue_number >= 388) {
					return <div key={newsletter.id} className="col xl4 l12">
						<div className="card blue-grey darken-1">
							<div className="card-content white-text">
								<a href={newsletter.url} className="card-title white-text">
									{!!newsletter.dateline ? newsletter.dateline : `Issue #${newsletter.issue_number}`}
								</a>
								<p>#{newsletter.issue_number}</p>
							</div>
							<div className="card-action">
								<a href={newsletter.url}>View</a>
							</div>
						</div>
					</div>
				} else {
					return <></>;
				}
			})}
		</div>
	);
}

export default NewsletterList;
