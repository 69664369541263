import React from 'react';
import spacetime from "spacetime";
import { Col } from 'react-materialize';

import styles from "./ReportListItem.module.css";
import {commaHandler} from "../../../Utils/text";

function ReportListItem(props) {
	const { report } = props;
	const time = spacetime(report.date, 'America/Los_Angeles');

	return <Col s={12} l={6}>
		<div className="card blue-grey darken-1">
			<div className="card-content white-text">
				<span className="card-title"><a className="white-text" href={"/report/" + report.url}>{report.title}</a></span>
				{report.date && <p>
					{!!report.month_only ? time.format('{year}-{iso-month}') : time.format('iso-short')}
				</p>}
				{report.authors && <p>
					by {report.authors.map((author, ind) => {
					return author.name + commaHandler(report.authors.length, ind);
				})}
				</p>}
			</div>
			<div className={styles.reportLinks + " card-action"}>
				<a href={"/report/" + report.url}>Read Report</a>
				{report.newsletter && <a href={report.newsletter.url}>More In Issue #{report.newsletter.issue_number}</a>}
			</div>
		</div>
	</Col>
}

export default ReportListItem;