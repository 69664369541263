import React from 'react';
import { Row, Col, Button } from 'react-materialize';
import useAxios from 'axios-hooks'
import styles from "./Newsletters.module.css";

import NewsletterList from "./Newsletters/NewsletterList";

function Newsletters() {
	const [{ data, loading, error }, refetch] = useAxios(
		process.env.REACT_APP_API_URL + '/newsletters'
	);

	return (
		<div>
			<Row>
				<Col s={12}>
					<h3 className="center-align">Issues</h3>
				</Col>
				<Col s={12}>
					{loading && <p className="center-align">Loading...</p>}
					{error && <p className="center-align">
						Error, could not load trip reports!<br/>
						<Button waves="light" onClick={refetch}>
							Try Again
						</Button>
					</p>}
					{data && <>
						<p>For older newsletters, <a className={styles.underlinedLink} href="https://desertpeaks.org/old/newsletterbackissues.htm">click here!</a></p>
						<NewsletterList newsletters={data.data}/>
					</>}
				</Col>
			</Row>
		</div>
	);
}

export default Newsletters;
