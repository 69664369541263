import React from 'react';
import { Row, Col } from 'react-materialize';

import styles from "./Index.module.css";

function Index() {
	return (
		<div className={styles.homepage}>
			<Row>
				<Col s={12}>
					<h4>Welcome to the website of the Desert Peaks Section's newsletter!</h4>
					<p>The Desert Peaks Section is a desert peakbagging & climbing section of the Angeles Chapter of the Sierra Club.</p>
					<p>This site is expanding and we hope to add more content over time. <a href="/reports">Click here</a> to view trip reports.</p>
				</Col>
			</Row>
		</div>
	);
}

export default Index;
