import React from 'react';
import { Navbar, NavItem, Container, Footer } from 'react-materialize';
import { BrowserRouter as Router, Route } from "react-router-dom";

import Index from "./Components/Index";
import Reports from "./Components/Reports";
import Report from "./Components/Reports/Report";
import Newsletters from "./Components/Newsletters";

import './App.css';

function App() {
	return (
		<Router>
			<div className="App">
				<Navbar
					brand={(
						<div>
							<a href={"/"}>The Desert Sage</a>
							<p className="hide-on-med-and-down">Official Magazine of the Desert Peaks Section</p>
						</div>
					)}
					alignLinks="right"
					className={"lime darken-4"}
				>
					<NavItem href="/reports">
						Trip Reports
					</NavItem>
					<NavItem href="/newsletters">
						Issues
					</NavItem>
				</Navbar>
				<Container>
					<Route path="/" exact component={Index} />
					<Route path="/reports" exact component={Reports} />
					<Route path="/report/:uri" exact component={Report} />
					<Route path="/newsletters" exact component={Newsletters} />
				</Container>
				<Footer
					copyrights="&copy; 2020 Desert Peaks Section, Angeles Chapter, Sierra Club"
					className={"lime darken-4"}
					links={<img
						src="https://d1ugk4h43khoa5.cloudfront.net/assets/DPSLogo-600x600.png"
						alt={"Desert Peaks Section Logo"}
						style={{
							maxWidth: '100%',
							height: '150px',
							float: 'right'
						}}
					/>}
				>
					<p>Visit the Desert Peaks Section website at <a href="https://desertpeaks.org/">desertpeaks.org.</a></p>
					<p>The Desert Peaks Section (DPS) is the desert peakbagging & climbing section of the <a href="https://angeles.sierraclub.org/">Angeles Chapter</a> of the <a href="https://www.sierraclub.org/">Sierra Club.</a></p>
				</Footer>
			</div>
		</Router>
	);
}

export default App;
