import React from 'react';
import { Row } from 'react-materialize';
import { chunk } from 'lodash';

import styles from "./ReportList.module.css";
import ReportListItem from "./ReportList/ReportListItem";

function ReportList(props) {
	const chunkedReports = !!props.reports && props.reports.length ? chunk(props.reports, 2) : [];

	return (
		<div>
			{chunkedReports.map((chunk, ind) => {
				const reports = !chunk[1] ? [chunk[0]] : [chunk[0], chunk[1]];
				return <Row className={styles.reportList} key={`rr-${ind}`}>
					{reports.map((report) => {
						return <ReportListItem key={`rli-${report.id}`} report={report}/>
					})}
				</Row>
			})}
		</div>
	)
}

export default ReportList;
