import spacetime from 'spacetime';

export const cleanData = data => {
	if (!!data && !!data.data) {
		return data.data;
	}
	return data;
};

export const getDateByline = (startDate, endDate = '', monthOnly = false) => {
	const format = monthOnly ? '{month} {year}' : '{month} {date-ordinal}, {year}';
	const start = spacetime(startDate, 'America/Los_Angeles').format(format);
	const end = !!endDate ? spacetime(endDate, 'America/Los_Angeles').format(format) : endDate;

	return start + (!!endDate ? ' – ' + end : '');
};